import { apiSlice } from '../apiSlice';
import { Region } from '../../types/Region';

const endpointUrl = '/region';

export const initialState: Region[] = [];

function getRegions() {
  return `${endpointUrl}`;
}

export const regionApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query }) => ({
    getAllRegions: query<Region[], void>({
      query: getRegions,
      providesTags: ['Regions']
    })
  })
});

export const { useGetAllRegionsQuery } = regionApiSlice;

export default regionApiSlice;
