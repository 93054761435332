import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUserDetails } from '../service/slices/authSlice';

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const userDetail = useSelector(selectUserDetails);

  if (userDetail) {
    const group = userDetail?.group as [];
    const isAdmin = group.some((x) => x === 'admin');
    if (!isAdmin) {
      return <Navigate to="/" />;
    }
    return <>{children}</>;
  }
  return <Navigate to="/" />;
};
