import Cookies from 'js-cookie';
import { decodeJwt } from 'jose';

export function makeLoginUrl() {
  const nonce = Math.random().toString(36);
  const state = Math.random().toString(36);
  const loginUrlParams = new URLSearchParams({
    client_id: `${process.env.REACT_APP_CLIENT}`,
    redirect_uri: `${process.env.REACT_APP_URL}/callback`,
    response_type: 'token id_token code',
    nonce: nonce,
    state: state
  });

  Cookies.set('nonce', nonce);
  Cookies.set('state', state);

  return `${process.env.REACT_APP_AUTH}/realms/${
    process.env.REACT_APP_REALM
  }/protocol/openid-connect/auth/?${loginUrlParams.toString()}`;
}

export function getUseDetails(accessToken: string) {
  const decodedAccessToken = decodeJwt(accessToken);
  const { name, group } = decodedAccessToken;
  const user = {
    name: name,
    group: group
  };
  return user;
}

export function validateToken(
  accessToken: string,
  idToken: string,
  state: string
) {
  const stateCookie = Cookies.get('state');
  const nonceCookie = Cookies.get('nonce');
  if (stateCookie !== state) {
    return false;
  }

  let decodedAccessToken = null;
  let decodedIdToken = null;

  try {
    decodedAccessToken = decodeToken(accessToken);
    decodedIdToken = decodeToken(idToken);
  } catch (e) {
    return false;
  }

  if (decodedAccessToken.nonce !== nonceCookie) {
    return false;
  }

  if (decodedIdToken.nonce !== nonceCookie) {
    return false;
  }

  const { name, group } = decodedAccessToken;

  const user = {
    name: name,
    group: group
  };

  return { accessToken, idToken, user };
}

export function decodeToken(token: string) {
  return decodeJwt(token);
}

export function makeLogoutUrl(id_token) {
  const logoutParams = new URLSearchParams({
    id_token_hint: id_token,
    post_logout_redirect_uri: `${process.env.REACT_APP_URL}`
  });

  Cookies.remove('nonce');
  Cookies.remove('state');

  return `${process.env.REACT_APP_AUTH}/realms/${
    process.env.REACT_APP_REALM
  }/protocol/openid-connect/logout?${logoutParams.toString()}`;
}

export function exchangeCodeForToken(code: string) {
  const tokenUrlParams = new URLSearchParams({
    client_id: `${process.env.REACT_APP_CLIENT}`,
    grant_type: 'authorization_code',
    code: code,
    redirect_uri: `${process.env.REACT_APP_URL}/callback`,
    nonce: Cookies.get('nonce') as string
  });

  return fetch(
    `${process.env.REACT_APP_AUTH}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: tokenUrlParams.toString()
    }
  )
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
}

export function makeTokenRenew(refreshToken: string) {
  const tokenUrlParams = new URLSearchParams({
    client_id: `${process.env.REACT_APP_CLIENT}`,
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  });

  return fetch(
    `${process.env.REACT_APP_AUTH}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/token`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },

      body: tokenUrlParams.toString()
    }
  )
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
}
