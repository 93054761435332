import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface Search {
  isLoading: boolean;
  records: any[];
}

const initialState: Search = {
  isLoading: false,
  records: []
};
export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  }
});
export const { setRecords, setLoading } = searchSlice.actions;
export const selectIsLoading = (state: RootState) => state.search.isLoading;
export const selectRecords = (state: RootState) => state.search.records;
