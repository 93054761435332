import { FC, ReactNode, useEffect, useState } from 'react';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import {
  setAccessRefreshToken,
  selectRefreshToken
} from 'src/service/slices/authSlice';

import Header from './Header';
import { makeTokenRenew } from 'src/security/keycloak';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const refreshToken = useSelector(selectRefreshToken);
  const [activeInterval, setActiveInterval] = useState(false);

  const RenewToken = async (refreshToken: string) => {
    console.log('Silence Renew');
    const res = await makeTokenRenew(refreshToken);

    dispatch(
      setAccessRefreshToken({
        accessToken: res.access_token,
        refreshToken: res.refresh_token
      })
    );
  };

  useEffect(() => {
    if (refreshToken && !activeInterval) {
      setActiveInterval(true);
      const interval = setInterval(() => {
        setActiveInterval(false);
        RenewToken(refreshToken);
      }, 25 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [refreshToken]);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                    lighten(theme.colors.primary.main, 0.7),
                    0.15
                  )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                    theme.colors.alpha.black[100],
                    0.1
                  )}, 0px 5px 12px -4px ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}`
          }
        }}
      >
        <Header />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
