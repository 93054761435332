import { Box, List, ListItem, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Logo from 'src/components/LogoSign';
import { useSelector } from 'react-redux';
import { selectUserDetails } from 'src/service/slices/authSlice';
import { useEffect, useState } from 'react';

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const [isAdmin, setAdmin] = useState(false);
  const userDetail = useSelector(selectUserDetails);

  useEffect(() => {
    if (userDetail) {
      const group = userDetail.group as [];
      setAdmin(group.some((x) => x === 'admin'));
    }
  }, [userDetail]);

  return (
    <>
      <Logo />
      <ListWrapper
        sx={{
          display: {
            md: 'block'
          }
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/home"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Home"
            />
          </ListItem>
          {isAdmin && (
            <ListItem
              classes={{ root: 'MuiListItem-indicators' }}
              button
              component={NavLink}
              to="/records"
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary="Registros"
              />
            </ListItem>
          )}
        </List>
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;
