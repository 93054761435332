import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  token: '',
  idToken: '',
  isLoading: false,
  user: null,
  isAuthenticated: false,
  refreshToken: '',
  expiresIn: ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAuth: (state, action) => {
      state.token = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.expiresIn = action.payload.expiresIn;
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    setAccessRefreshToken: (state, action) => {
      state.token = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    }
  }
});

export const {
  setAuthenticated,
  setLoading,
  setAuth,
  setUserDetails,
  setAccessRefreshToken
} = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

export const selectUserDetails = (state: RootState) => state.auth.user;
export const selectIsLoading = (state: RootState) => state.auth.isLoading;
export const selectToken = (state: RootState) => state.auth.token;
export const selectIdToken = (state: RootState) => state.auth.idToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
