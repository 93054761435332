import { apiSlice } from '../apiSlice';

const endpointUrl = '/picture';

export const initialState: any[] = [];

function deletePicture({ id }: { id: string }) {
  return {
    url: `${endpointUrl}/${id}`,
    method: 'DELETE'
  };
}

export const pictureApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    deletePicture: mutation<any, { id: string }>({
      query: deletePicture
    }),
    uploadFile: mutation<any, FormData>({
      query(data) {
        return {
          url: `${endpointUrl}`,
          method: 'POST',
          body: data,
          formData: true
        };
      }
    })
  })
});

export const { useDeletePictureMutation, useUploadFileMutation } =
  pictureApiSlice;

export default pictureApiSlice;
