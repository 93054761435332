import { apiSlice } from '../apiSlice';
import { Shop } from '../../types/Shop';

const endpointUrl = '/shop';

export const initialState: Shop = {
  id: '',
  description: ''
};

function getShops() {
  return `${endpointUrl}`;
}

export const shopApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query }) => ({
    getAllShops: query<Shop[], void>({
      query: getShops,
      providesTags: ['Shops']
    })
  })
});

export const { useGetAllShopsQuery } = shopApiSlice;
